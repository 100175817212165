<template>
  <v-sheet class="segment" id="segment" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="8" class="my-auto">
        <v-layout>
          <v-col md="4" class="my-auto"
            ><h1 class="custom-header-blue-text m-0 text-truncate ml-1">
              {{ segment?.name }}
            </h1></v-col
          >
          <!-- <v-flex md8> </v-flex> -->
        </v-layout>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn
          :disabled="pageLoading"
          depressed
          tile
          class="mx-2 white--text"
          color="blue darken-4"
          @success="getSegment()"
          @click="generateSegmentDialog = true"
        >
          Edit
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Back
        </v-btn>
      </v-col>
      <!-- <v-container v-if="false" class="d-flex justify-end" fluid>
        <v-btn
          :disabled="pageLoading"
          depressed
          tile
          class="mx-2 white--text"
          color="blue darken-4"
          @success="getSegments()"
          @click="generateSegmentDialog = true"
        >
          Edit
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Back
        </v-btn>
      </v-container> -->
      <v-container fluid>
        <v-row>
          <v-col md="8">
            <table v-if="segment" width="100%" class="detail-table mb-10">
              <tr>
                <th width="200">Total Contact</th>
                <td class="text-lowercase">
                  <ValueTemplate v-model="segment.total_contacts" title="contact"></ValueTemplate>
                </td>
                <th width="200">Used Time</th>
                <td>
                  <ValueTemplate
                    v-model="segment.total_time_used"
                    title="used time"
                  ></ValueTemplate>
                </td>
              </tr>

              <tr>
                <th width="200">Last Sync</th>
                <td>
                  <ValueTemplate date v-model="segment.sync_time" title="last sync"></ValueTemplate>
                </td>
                <th width="200">Created At</th>
                <td>
                  <ValueTemplate date v-model="segment.added_at" title="created at"></ValueTemplate>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="4">
            <div class="p-2 grey lighten-4" style="height: 100%">
              <div class="font-weight-bold mb-2">SEGMENT FILTERS:</div>
              <template v-for="(item, index) in segment?.filters">
                <Chip
                  small
                  style="text-transform: uppercase"
                  :key="index"
                  class="mr-2"
                  color="blue darken-4"
                  :text="item.name"
                ></Chip>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="mx-6">
        <v-row class="d-flex">
          <!-- <v-col md="6" class="custom-header-blue-text" style="font-size: 15px; font-weight: 700;margin-top:8px">
            Customer
          </v-col> -->
          <v-col md="12" class="d-flex justify-end">
            <v-text-field
              ref="search"
              @keyup.enter="getSegment()"
              @keyup="customerSearchKeyUp"
              placeholder="Search customer"
              autocomplete="off"
              hide-details
              v-model="customerSearch"
              outlined
              style="max-width: 450px; min-width: 400px"
            >
              <template #prepend-inner>
                <v-icon color="grey"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-container fluid>
        <v-simple-table class="bt-table mt-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="50">Customer #</th>
                <th width="250">Name</th>
                <th width="100">Customer Phone</th>
                <th width="100">Email</th>
                <th width="100">Is Subscribed</th>
                <th width="100">Action</th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <template v-if="segment?.contacts.rows.length">
                <tr v-for="(row, bkey) in segment.contacts.rows" :key="bkey">
                  <td width="50"><Chip :text="row.customer_barcode"></Chip></td>
                  <td>
                    <ValueTemplate v-model="row.full_name" title="name"></ValueTemplate>
                  </td>

                  <td>
                    <ValueTemplate
                      v-model="row.customer_phone_number"
                      title="phone no"
                    ></ValueTemplate>
                  </td>
                  <td>
                    <ValueTemplate v-model="row.customer_email" title="email"></ValueTemplate>
                  </td>
                  <td>
                    <v-switch
                      v-on:click.stop.prevent="changeSegmentStatus(row.activated, row.id)"
                      color="blue darken-4"
                      v-model="row.activated"
                    ></v-switch>
                  </td>
                  <td>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn
                            @click="deleteConfirm(row.customer_barcode, row.id)"
                            depressed
                            fab
                            dark
                            x-small
                            color="red"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="7">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no order at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 7" :key="idr">
                <td v-for="idk in 7" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <v-layout class="light-border-top mt-4">
          <v-flex md6>
            <label class="btx-label p-4">{{ segment?.contacts?.showing_string }}</label>
          </v-flex>
          <v-flex md6>
            <v-pagination
              color="blue darken-4"
              v-model="currentPage"
              @change="getSegment"
              :length="segment?.contacts?.totalPages"
              :total-visible="7"
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-container>
      <v-col md="12">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          v-if="pageLoading"
          class="position-absolute"
          color="blue darken-4"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <Dialog :dialog.sync="deleteDialog" :dialogWidth="750">
      <template v-slot:title> Delete Customer</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              <span class="text-capitalize">Segments </span>
              <b class="red--text text--lighten-1">{{ deleteText }}</b> will be deleted forever and
              cannot be retrieved later.<br />Are you sure about deleting it?
            </p>
          </v-col>
          <v-col md="12" class="pb-0 my-auto">
            <em>Note: All transactions of this segment will also be deleted.</em>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="pageLoading"
          :disabled="pageLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteCustomer()"
        >
          Yes! Delete
        </v-btn>
        <v-btn depressed tile :disabled="pageLoading" v-on:click="deleteDialog = false">
          No, Close
        </v-btn>
      </template>
    </Dialog>
    <CreateSegment
      v-if="generateSegmentDialog"
      :dialog="generateSegmentDialog"
      @close="generateSegmentDialog = false"
      @success="getSegment()"

    />
  </v-sheet>
</template>

<script>
import ValueTemplate from "@/view/components/ValueTemplate";
import Chip from "@/view/components/Chip";
import { GET_SEGMENT } from "@/core/lib/marketing.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import {
  CHANGE_SEGMENTS_CUSTOMER_STATUS,
  DELETE_SEGMENTS_CUSTOMERS,
} from "@/core/lib/marketing.lib";
import CreateSegment from "@/view/module/marketing/CreateSegment";
import Dialog from "@/view/components/Dialog";

export default {
  name: "segment-detail",
  title: "Detail segment",
  components: {
    ValueTemplate,
    Chip,
    CreateSegment,
    Dialog,
  },
  data() {
    return {
      segment: null,
      pageLoading: false,
      deleteDialog: false,
      deleteId: null,
      deleteText: null,
      currentPage: 1,
      customerSearch: null,
      generateSegmentDialog: false,
    };
  },
  methods: {
    async getSegment() {
      const _this = this;
      try {
        const segments = await GET_SEGMENT(
          this.$route.params?.id,
          this.currentPage,
          this.customerSearch
        );
        this.segment = segments;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
    customerSearchKeyUp() {
      if (!this.customerSearch) {
        console.log(this.customerSearch);
        this.getSegment();
      }
    },
    async deleteCustomer() {
      this.pageLoading = true;
      const _this = this;
      const params = {
        selected: [_this.deleteId],
      };
      try {
        await DELETE_SEGMENTS_CUSTOMERS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Customer delete Successfully." },
        ]);
        await this.getSegment();
        this.deleteDialog = false;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async deleteConfirm(barcode, id) {
      this.deleteText = barcode;
      this.deleteId = id;
      this.deleteDialog = true;
    },
    async changeSegmentStatus(status, id) {
      const _this = this;
      _this.pageLoading = true;
      const params = {
        action: status == 1 ? "active" : "inactive",
        selected: [id],
      };
      try {
        await CHANGE_SEGMENTS_CUSTOMER_STATUS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Customer Status Changed Successfully." },
        ]);
        await this.getSegment();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  async mounted() {
    if (this.$route.params?.id) {
      await this.getSegment();
    }
  },
};
</script>
